<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        {{ $t("SEO") }}
      </v-card-title>
      <v-card-text>
        <h3 class="mb-3 mt-3">{{ $t("Page title") }}</h3>
        <v-row no-gutters>
          <v-col xs="12" sm="2" lg="1">
            <v-select
              :items="pageLanguages"
              v-model="pageDefaultLanguage"
              single-line
              class="pageElementLanguageSelect"
            ></v-select>
          </v-col>
          <v-col xs="12" sm="10" lg="11">
            <template
              v-for="(translatedValue, translatedValueIndex) in page.Title"
            >
              <template v-if="pageDefaultLanguage == translatedValueIndex">
                <input
                  :key="translatedValueIndex"
                  class="page-detail textInput mt-2 mb-4"
                  v-model="page.Title[translatedValueIndex]"
                  type="text"
                />
              </template>
            </template>
          </v-col>
        </v-row>
        <h3 class="mb-3 mt-3">{{ $t("Page description") }}</h3>
        <v-row no-gutters>
          <v-col xs="12" sm="2" lg="1">
            <v-select
              :items="pageDescriptions"
              v-model="pageDefaultDescription"
              single-line
              class="pageElementLanguageSelect"
            ></v-select>
          </v-col>
          <v-col xs="12" sm="10" lg="11">
            <template
              v-for="(
                translatedValue, translatedValueIndex
              ) in page.Description"
            >
              <template v-if="pageDefaultDescription == translatedValueIndex">
                <textarea
                  :key="translatedValueIndex"
                  class="page-detail textInput mt-2"
                  v-model="page.Description[translatedValueIndex]"
                  rows="4"
                />
              </template>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: [Array, Object],
    },
    pageDescriptions: {
      type: [Array, Object],
    },
    pageDefaultLanguageProp: {},
    pageDefaultDescriptionProp: {},
    pageLanguages: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      pageDefaultLanguage: this.pageDefaultLanguageProp,
      pageDefaultDescription: this.pageDefaultDescriptionProp,
    };
  },
};
</script>

<style>
</style>